
























import { defineComponent } from "@vue/composition-api";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";

export default defineComponent({
  components: {
    TextFilter,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    treeItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      treeOpen: [],
    };
  },
  computed: {
    selectedItemsString: {
      get(): string {
        return this.value?.map((item) => (item as Record<string, unknown>).name).join(", ") || "";
      },
      set(value: string) {
        if (!value) {
          this.$emit("change", []);
        }
      },
    },
  },
  methods: {
    showModal() {
      this.dialogVisible = true;
    },
    closeModal() {
      this.dialogVisible = false;
    },
    saveSelection() {
      this.$emit("change", this.value || []);
      this.closeModal();
    },
    changeTree(value: Record<string, unknown>[]) {
      this.$emit("change", value);
    },
  },
});
