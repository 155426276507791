






















import { defineComponent } from "@vue/composition-api";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import IdCell from "@monorepo/uikit/src/components/tableViews/IdCell.vue";

export default defineComponent({
  components: {
    OpenCardButton,
    IdCell,
  },
  props: {
    event: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      currentItem: 1,
    };
  },
  computed: {
    highlight(): string {
      return this.event.highlights[this.currentItem - 1];
    },
    regexObj(): (str: string) => { regex: RegExp; className: string } {
      return (str: string) => {
        switch (true) {
          case str.includes("#!!"):
            return { regex: /#!!([^#!!]+)#!!/g, className: "table__higlight-frequency" };
          case str.includes("%!!"):
            return { regex: /%!!([^%!!]+)%!!/g, className: "table__higlight-ngramed" };
          case str.includes("$!!"):
            return { regex: /\$!!([^$!!]+)\$!!/g, className: "table__higlight-synonym" };
          default:
            return { regex: /@!!([^@!!]+)@!!/g, className: "table__higlight-morph" };
        }
      };
    },
    parsedHiglightString(): (str: string) => { text: string; class: string }[] {
      return (str: string) => {
        if (!str?.length) {
          return [];
        }
        const regex = this.regexObj(str).regex;
        const className = this.regexObj(str).className;
        let match;
        let lastIndex = 0;
        const parsed = [];

        while ((match = regex.exec(str)) !== null) {
          const index = match.index + 1;

          if (index > lastIndex) {
            parsed.push({
              text: str.slice(lastIndex, index - 1),
              class: "",
            });
          }

          parsed.push({
            text: match[1],
            class: className,
          });

          lastIndex = index + match[0].length - 1;
        }

        if (lastIndex < str.length) {
          parsed.push({
            text: str.slice(lastIndex),
            class: "",
          });
        }
        return parsed;
      };
    },
  },
  watch: {
    "event.highlights"() {
      this.currentItem = 1;
    },
  },
});
